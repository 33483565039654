import React from 'react';

export const pricings = [
  //   {
  //   title: 'Starter',
  //   subtitle: 'Perfect for your small private events!',
  //   monthly: 22,
  //   annual: 16,
  //   priceSuffix: ' / Month',
  //   features: [
  //     <span><span style={{ color: 'rgb(255,100,83)' }}><b>200</b></span> guests per event</span>,
  //     <span><span style={{ color: 'rgb(255,100,83)' }}><b>Unlimited</b></span> events</span>,
  //     <span><span style={{ color: 'rgb(255,100,83)' }}><b>Unlimited</b></span> devices</span>,
  //     <span><span style={{ color: 'rgb(255,100,83)' }}><b>Full</b></span> access to all features</span>
  //   ],
  //   disclaimer: 'Full support is included for the whole length of the subscription',
  //   isHighlighted: false,
  // },
  {
    title: 'Starter',
    subtitle: 'Perfect for your small private events!',
    monthly: 22,
    annual: 16,
    priceSuffix: ' / Month',
    features: [
      <span><span style={{ color: 'rgb(255,100,83)' }}><b>200</b></span> guests per event</span>,
      <span><span style={{ color: 'rgb(255,100,83)' }}><b>Unlimited</b></span> events</span>,
      <span><span style={{ color: 'rgb(255,100,83)' }}><b>Unlimited</b></span> devices</span>,
      <span><span style={{ color: 'rgb(255,100,83)' }}><b>Full</b></span> access to all features</span>
    ],
    // disclaimer: <div>Monthly subscription do not automatically renew by default</div>,
    isHighlighted: false,
  },
  {
    title: 'Premium',
    subtitle: 'Our most used plan. Ideal for large events!',
    monthly: 39,
    annual: 32,
    priceSuffix: ' / Month',
    features: [
      <span><span style={{ color: 'rgb(255,100,83)' }}><b>500</b></span> guests per event</span>,
      <span><span style={{ color: 'rgb(255,100,83)' }}><b>Unlimited</b></span> events</span>,
      <span><span style={{ color: 'rgb(255,100,83)' }}><b>Unlimited</b></span> devices</span>,
      <span><span style={{ color: 'rgb(255,100,83)' }}><b>Full</b></span> access to all features</span>
    ],
    // disclaimer: 'Full support is included for the whole length of the subscription',
    isHighlighted: true,
  },
  {
    title: 'Professional',
    subtitle: 'Well-suited for your large scale events!',
    monthly: 89,
    annual: 75,
    priceSuffix: ' / Month',
    features: [
      <span><span style={{ color: 'rgb(255,100,83)' }}><b>Unlimited</b></span> guests per event</span>,
      <span><span style={{ color: 'rgb(255,100,83)' }}><b>Unlimited</b></span> events</span>,
      <span><span style={{ color: 'rgb(255,100,83)' }}><b>Unlimited</b></span> devices</span>,
      <span><span style={{ color: 'rgb(255,100,83)' }}><b>Full</b></span> access to all features</span>
    ],
    // disclaimer: 'Full support is included for the whole length of the subscription',
    isHighlighted: false,
  },
];

export const faq = [
  {
    id: 'faq-1',
    title: 'Flexible access to facilities.',
    subtitle: 'Our new key fobs make it so easy!',
    text:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt rerum minima a possimus, amet perferendis, temporibus obcaecati pariatur.',
    link: 'Check it out',
  },
  {
    id: 'faq-2',
    title: 'Snacks, drinks, coffee, and more.',
    subtitle: 'Our new key fobs make it so easy!',
    text:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt rerum minima a possimus, amet perferendis, temporibus obcaecati pariatur.',
    link: 'Check it out',
  },
  {
    id: 'faq-3',
    title: 'On site tech support.',
    subtitle: 'Our new key fobs make it so easy!',
    text:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt rerum minima a possimus, amet perferendis, temporibus obcaecati pariatur.',
    link: 'Check it out',
  },
  {
    id: 'faq-4',
    title: 'Flexible access to facilities.',
    subtitle: 'Our new key fobs make it so easy!',
    text:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt rerum minima a possimus, amet perferendis, temporibus obcaecati pariatur.',
    link: 'Check it out',
  },
  {
    id: 'faq-5',
    title: 'Snacks, drinks, coffee, and more.',
    subtitle: 'Our new key fobs make it so easy!',
    text:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt rerum minima a possimus, amet perferendis, temporibus obcaecati pariatur.',
    link: 'Check it out',
  },
  {
    id: 'faq-6',
    title: 'On site tech support.',
    subtitle: 'Our new key fobs make it so easy!',
    text:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt rerum minima a possimus, amet perferendis, temporibus obcaecati pariatur.',
    link: 'Check it out',
  },
];

export const plans = [
  {
    title: 'Move deals through your funnel – fast',
    subtitle:
      'Our chatbots and live chat capture more of your best leads and convert them while they’re hot.',
  },
  {
    title: 'On demand services right at your front-door',
    subtitle:
      'Our chatbots and live chat capture more of your best leads and convert them while they’re hot.',
  },
  {
    title: 'Online 24/7 support',
    subtitle:
      'Our chatbots and live chat capture more of your best leads and convert them while they’re hot.',
  },
];
